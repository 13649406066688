"use strict";

module.exports = function () {
    $(".startup-kit-page").on("mouseover mouseout", ".btn, .c-button--primary.btn-color-filled", function () {
        var bgColor = window.getComputedStyle(this).backgroundColor;
        var color = window.getComputedStyle(this).color;
    
        this.style.setProperty("background-color", color, "important");
        this.style.setProperty("color", bgColor, "important");
    });
};
